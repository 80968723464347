window.hypothesisConfig = function () {
  return {
    branding: {
      appBackgroundColor: 'white',
      ctaBackgroundColor: 'rgb(247, 52, 84)',
      ctaTextColor: 'white',
      accentColor: 'rgb(247, 52, 84)',
      selectionFontFamily: 'helvetica, arial, sans serif'
    }
  };
};
